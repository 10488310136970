<template>
  <div class="add-new-item">
    <InputText
      type="text"
      v-model="newProduct.ItemName"
      placeholder="רשום שם פריט"
      style="width:90%; margin-bottom: 1rem;"
    />
    <InputText
      type="text"
      v-model="newProduct.barkod"
      placeholder="רשום ברקוד פריט"
      style="width:90%; margin-bottom: 1rem;"
    />
    <InputText
      type="number"
      v-model="newProduct.stock"
      placeholder="רשום מלאי פתיחה"
      style="width:90%; margin-bottom: 1rem;"
    />

    <Button
      label="הוסף"
      style="width:90%; height:50px; font-size: 1.2rem; margin-top: 1rem"
      class="p-button-success"
      @click="addNewItem"
    />
    <Button
      label="חזור"
      style="width:90%; height:50px; font-size: 1.2rem; margin-top: 1rem"
      class="p-button-danger"
      @click="handle_back"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue'
import InputText from "primevue/inputtext";
import {projectFirestore} from '../../../../../../firebase/config'
import Dropdown from 'primevue/dropdown';
export default {
    components:{InputText,Dropdown},
    emits:['hide'],
    setup(_,{ emit }){
        class Product{
            constructor(ItemName,barkod,stock){
                this.barkod=barkod
                this.ItemName=ItemName
                this.stock=stock
                this.image=""
                this.history_costs=[]
                this.cost=0,
                this.visible="true"
            }
        }
        const newProduct = ref(new Product);
        
        const addNewItem=async()=>{
          console.log((Object.assign({openStock:newProduct.value.stock}, newProduct.value)));
            try{
            await projectFirestore
            .collection("Applications")
            .doc("iW7LKA8GsRduHDOSFxq1")
            .collection('Products')
            .doc(newProduct.value.barkod)
            .set((Object.assign({openStock:newProduct.value.stock}, newProduct.value)))
            
             emit('hide');
            }catch(err){
                console.log(err.message);
            }
           
        }

        const handle_back = ()=>{
          emit('hide')
        }
        
        return {newProduct,addNewItem,handle_back}
    }
}
</script>

<style scoped>
.add-new-item {
  height: 100%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}
</style>