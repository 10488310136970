<template>
  <div class="orders-of-today-main">
        <p style="color:#fff; font-size: 2rem">ריכוז פריטים להיום - {{new Date().toLocaleDateString('he')}}</p>
        <Button
            label="ריכוז פריטים להיום - מחושב"
            style="width:260px; height:100px; margin:5px; font-size: 2rem;"
            class="p-button-success"
            @click="state='OrdersOfToday'"
        />
        <Button
            label="ריכוז פריטים להיום - כללי"
            style="width:260px; height:100px; margin:5px; font-size: 2rem;"
            class="p-button-warning"
            @click="state='OrdersOfTodayGeneral'"
        />
        <Button
            label="חזור"
            style="width:260px; height:40px; margin:5px; font-size: 2rem;"
            class="p-button-danger"
            @click="handle_close"
        />
        <component @close="state=null" :is="state"/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import OrdersOfToday from './OrdersOfToday.vue'
import OrdersOfTodayGeneral from  './OrdersOfTodayGeneral.vue'
export default {
    components:{OrdersOfToday,OrdersOfTodayGeneral},
    emits:['close'],
    setup(_,{emit}){
        const state = ref(null)

        const handle_close = ()=>{
            emit('close')
        }
        return{handle_close,state}
    }
}
</script>

<style scoped>
    .orders-of-today-main{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 19;
        padding: 0 5px;
        color: #333;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        background: var(--main);
        text-align: center;
    }
</style>