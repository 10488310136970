<template>
  <div class="orders-summery">
    <div class="tools-bar-btns" style="width:100%;">
      <Button
        label="הצג הכל"
        class="p-button-secondary"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px; color:#fff"
        @click="ordersShowAll"
      />
      <Button
        label="הצג חדשים"
        class="p-button-info"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px;"
        @click="ordersShowNew"
      />
      <Button
        label="הצג טופלו"
        class="p-button-success"
        style="font-size: 1.2rem; margin-bottom:0.5rem; margin-left:5px;"
        @click="ordersShowDone"
      />
    </div>

    <template v-for="order in filteredOrders" :key="order.createdAt">
      <div class="orders-summery-line" @click="showOrderToPrint(order)">
        <div
          class="order-status"
          style="display:flex; gap: 10px; cursor: pointer;"
        >
          <p style="font-weight:500;">סטטוס:</p>
          <p v-if="order.status == 'open'" style="color:blue">חדש</p>
          <p v-if="order.status == 'close'" style="color:green">טופל</p>
        </div>
        <div class="order-date" style="display:flex; gap: 10px;">
          <p style="font-weight:500;">תאריך:</p>
          <p>{{ order.createdAt.toDate().toLocaleString() }}</p>
        </div>
        <div class="order-branch" style="display:flex; gap: 10px;">
          <p style="font-weight:500;">סניף:</p>
          <p>{{ order.branche }}</p>
        </div>
        <div class="order-products" style="display:flex; gap: 10px;">
          <p style="font-weight:500;">מס מוצרים:</p>
          <p>{{ order.products.length }}</p>
        </div>
      </div>
    </template>
  </div>
  <div>
    <!-- orders to print -->
    <Dialog
      header="הזמנה"
      v-model:visible="showOrderPrint"
      :modal="true"
      position="top"
    >
      <div id="orderToShow">
        <div class="print">
          <Button
            id="print-order-hide"
            label="הדפס הזמנה"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-success"
            @click="printOrder"
          />
        </div>
        <div
          class="order-title"
          style="display:flex; justify-content:center; flex-direction:column; gap:10px; align-items:center; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size:1.5rem;">
            הזמנה חדשה: סניף {{ orderToPrint.branche }}
          </p>
          <p style="font-size:1.25rem;">
            תאריך: {{ orderToPrint.createdAt.toDate().toLocaleString() }}
          </p>
        </div>
        <div
          class="notes-order"
          style="display:flex; gap:5px; flex-direction:column; margin-top:10px; background-color:whitesmoke; border-radius:5px; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size: 1.2rem; font-weight:500; direction:rtl;">
            הערות להזמנה
          </p>
          <p style="font-size: 1.1rem;  direction:rtl;">
            {{ orderToPrint.notes }}
          </p>
        </div>

        <div
          class="products-list-order"
          style="display:flex; flex-direction:column; gap: 10px; margin-top:1rem; width:100%; direction:rtl; font-family: 'Rubik', sans-serif;"
        >
          <div
            class="orders-title"
            style="display:flex; margin-top:1rem; width:100%; text-align:center; font-size:1.3rem; font-weight:500;"
          >
            <p style="width:33%">קוד</p>
            <p style="width:33%">שם</p>
            <p style="width:33%">כמות</p>
          </div>
          <div
            class="line-sep"
            style="border:1px solid grey; width:100%;"
          ></div>

          <template v-for="product in orderToPrint.products" :key="product.id">
            <div
              class="product-order"
              style="display:flex; text-align:center; width:100%; font-size:1.25rem;"
            >
              <p style="width:33%">{{ product.barkod }}</p>
              <p style="width:33%">{{ product.ItemName }}</p>
              <p style="width:33%">{{ product.currentOrder }}</p>
            </div>
            <div
              class="line-sep"
              style="border:1px solid grey; width:100%; margin-bottom:10px;"
            ></div>
          </template>
        </div>

        <div
          class="print-tools"
          style="width:90vw; display:flex; gap:10px; justify-content:center; margin-top:1rem;"
        ></div>
      </div>

      <div id="orderToPrint" v-show="false">
        <div
          class="order-title"
          style="display:flex; justify-content:center; flex-direction:column; gap:10px; align-items:center; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size:1.5rem;">
            הזמנה חדשה: סניף {{ orderToPrint.branche }}
          </p>
          <p style="font-size:1.25rem;">
            תאריך: {{ orderToPrint.createdAt.toDate().toLocaleString() }}
          </p>
        </div>
        <div
          class="notes-order"
          style="display:flex; gap:5px; flex-direction:column; margin-top:10px; background-color:whitesmoke; border-radius:5px; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size: 1.2rem; font-weight:500; direction:rtl;">
            הערות להזמנה
          </p>
          <p style="font-size: 1.1rem;  direction:rtl;">
            {{ orderToPrint.notes }}
          </p>
        </div>

        <div
          class="products-list-order"
          style="display:flex; flex-direction:column; gap: 10px; margin-top:1rem; width:100%; direction:rtl; font-family: 'Rubik', sans-serif;"
        >
          <div
            class="orders-title"
            style="display:flex; margin-top:1rem; width:100%; text-align:center; font-size:1.3rem; font-weight:500;"
          >
            <p style="width:20%">קוד</p>
            <p style="width:40%">שם</p>
            <p style="width:20%">כמות</p>
            <p style="width:20%">משקל</p>
          </div>
          <div
            class="line-sep"
            style="border:1px solid grey; width:100%;"
          ></div>

          <template
            v-for="(product, index) in orderToPrint.products"
            :key="product.id"
          >
            <div
              class="product-order"
              style="display:flex; text-align:center; width:100%; font-size:1.33rem;"
            >
              <p style="width:20%">{{ product.barkod }}</p>
              <p style="width:40%">{{ product.ItemName }}</p>
              <p style="width:20%">{{ product.currentOrder }}</p>
              <p style="width:20%; border-bottom: 1px solid grey;"></p>
            </div>
            <div
              class="line-sep"
              style="border:1px solid grey; width:100%; margin-bottom:10px;"
            ></div>

            <div
              v-if="index % 5 == 0 && index != 0"
              style="page-break-after:always"
            ></div>
          </template>
        </div>

        <div
          class="print-tools"
          style="width:90vw; display:flex; gap:10px; justify-content:center; margin-top:1rem;"
        ></div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "vue";
import Dialog from "primevue/dialog";
import {projectFirestore} from '../../../../firebase/config'
import store from '../../../../store';
import swal from "sweetalert";

/* eslint-disable */
export default {
  components:{Dialog},
  setup(){

    const orders=ref([])
    const ordersFilter=ref('')
    const showOrderPrint = ref(false);
    const orderToPrint = ref(null)
    
    const userProfile = ref(
      computed(() => {
        return store.getters.user;
      })
    );

    const userRole = ref(computed(()=>{
        return store.getters.role
    }))

    const filteredOrders = ref(computed(() => {
      
        if(ordersFilter.value == ''){
          return orders.value
        }
        if(ordersFilter.value == 'open'){
          return orders.value.filter(order => {
            return order.status == 'open'
          })
        }
        if(ordersFilter.value == 'close'){
          return orders.value.filter(order => {
            return order.status == 'close'
          })
        }
    }))

    const ordersShowAll=()=> {
      ordersFilter.value = "";
    }
    const ordersShowDone=()=> {
      ordersFilter.value = "close";
    }
    const ordersShowNew=()=> {
      ordersFilter.value = "open";
    }
 
    const showOrderToPrint = order=>{
       orderToPrint.value = order
       showOrderPrint.value = true
    }

    const printOrder=()=>{
    const divContents = document.getElementById("orderToPrint").innerHTML;
    var a = window.open('', '', 'height=800, width=600'); 
    a.document.write('<html>'); 
    a.document.write('<style>'); 
    a.document.write('@media print{#print-order-hide {visibility: hidden; } #delete-order-hide { visibility: hidden; } #mark-btn-hide {visibility: hidden;}}')
    a.document.write('</style>'); 
    a.document.write(divContents); 
    a.document.write('</body></html>'); 
    a.document.close(); 
    a.print(); 
    }

    let unsub
    const getOrders=()=>{
     const role = userRole.value
     const branche = userProfile.value.branche
     console.log('branche:',branche);
     if(role>=25){
       unsub = projectFirestore
      .collection("Applications")
      .doc("iW7LKA8GsRduHDOSFxq1")
      .collection('Orders')
      .where("branche","==",branche)
      .orderBy('createdAt','desc').onSnapshot(snap=>{
        let results = [];
        snap.docs.forEach(doc => {
        // must wait for the server to create the timestamp & send it back
        doc.data().createdAt && results.push({ ...doc.data(), id: doc.id });
      });

      orders.value=results
      },err=>{
        console.log(err.message);
        orders.value = null;
      })
     }else{
       swal("אינך יכול לראות דף זה", "דף זה מיועד לסניפים", "error");
     }
    
    }
    onMounted(()=>{
      getOrders()
    })

    onUnmounted(()=>{
        if(unsub){
            unsub()
        }
    })


    return{
      userProfile,
      orders,
      getOrders,
      ordersShowAll,
      ordersShowDone,
      ordersShowNew,
      filteredOrders,
      showOrderToPrint,
      orderToPrint,
      showOrderPrint,
      printOrder,
      }
  }
};
</script>

<style scoped>
.orders-summery {
    width: 80vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.orders-summery-line {
  position: relative;
  width: 100%;
  border: 1px solid rgb(175, 166, 166);
}

.tools-bar-btns{
  margin-top: 1.2rem;
  display: flex;
}




  @media print { 
   
     #page-down {
      page-break-after: always;
      
    } 
    #print-order-hide { 
      visibility: hidden; 
    }
    #delete-order-hide { 
      visibility: hidden; 
    }
    #mark-btn-hide { 
      visibility: hidden; 
    }

  }



</style>
