<template>
  <div class="container">
    <h3>במסך זה עליך לדווח על בעיה אשר התרחשה לאחר שהזמנת הירקות התקבלה</h3>
    <div class="wrapper">
      <div class="field">
        <p>סיבת הבעיה</p>
        <Dropdown
          v-model="values.reason"
          :options="reasonsOptions"
          placeholder="עליך לבחור סיבה"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>תיאור הבעיה</p>
        <Textarea
          v-model="values.description"
          rows="5"
          cols="30"
          style="width:100%"
          placeholder="עליך לתאר את מהות הבעיה"
        />
      </div>
      <div v-if="!isPending" class="field">
        <Button
          label="הוסף צילום"
          icon="pi pi-camera"
          iconPos="left"
          class="p-button-outlined"
          style="width:100%"
          @click="handleUpload"
        />
      </div>
      <div v-if="isPending" class="field">
        <Button
          label="ממתין להעלאה..."
          icon="pi pi-camera"
          iconPos="left"
          class="p-button-outlined"
          style="width:100%"
          disabled
        />
      </div>
      <div class="images">
        <template
          v-for="document in values.uploadedDocuments"
          :key="document.filePath"
        >
          <div class="image">
            <img
              v-if="document.type != 'application/pdf'"
              :src="document.url"
            />
            <img
              v-if="document.type == 'application/pdf'"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
              @click="handleOpenPdf(document)"
            />
            <div class="delete-bar" @click="delete_file(document)">
              <p>מחק</p>
            </div>
          </div>
        </template>
      </div>
    </div>

    <Button
      label="שלח"
      class="p-button-success"
      style="width:100%"
      @click="handleSubmit"
    />
    <input
      v-show="false"
      id="upload-image"
      type="file"
      @change="handleChange"
    />
  </div>
</template>

<script>
/* eslint-disable */
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import swal from "sweetalert";
import {projectFirestore} from '../../../../firebase/config'
import use_yerakot_storage from '../../../../Methods/use_yerakot_storage'
import { onMounted, onUnmounted, ref } from 'vue';
import store from '../../../../store';

export default {
    components:{Dropdown,Textarea},
    setup(){
        const { url, filePath, uploadReport, deleteImage } = use_yerakot_storage();
        const isPending=ref(false)
        const error = ref("");
        const file = ref(null);
        const fileError = ref(null);

        
        const reasonsOptions=ref([
            'חוסר בהזמנה',
            'אי התאמה',
            'פריט פגום'
        ])
        const handleUpload=()=>{
            document.getElementById("upload-image").click();
        }
        const values=ref({
            reason:'',
            description:'',
            uploadedDocuments:[],
            branche:'',
            status:'חדש',
            tihud:'',
            createdAt:new Date()
        })

        const handleChange = async e => {
            isPending.value = true;
            const types = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
            const selected = e.target.files[0];
            console.log(selected);
            if (selected && types.includes(selected.type)) {
                file.value = selected;
                fileError.value = null;
                if (values.value.uploadedDocuments.length < 3) {
                    await uploadReport(file.value,values.value.branche);
                    values.value.uploadedDocuments.push({
                        url: url.value,
                        filePath: filePath.value,
                        type: selected.type
                    });
                }
            } else {
                console.log("error");
                file.value = null;
                fileError.value =
                "סוג קובץ זה אינו נתמך, נא להעלות קובץ תמונה או PDF בלבד!";
            }
            isPending.value = false;
        };

        const handleOpenPdf = document => {
            window.open(document.url, "_blank");
        };
        const delete_file = async document => {
            const index = values.value.uploadedDocuments.findIndex(
                doc => (doc.filePath = document.filePath)
            );
            values.value.uploadedDocuments.splice(index, 1);
            await deleteImage(document.filePath);
        };

        const validation=()=>{
            for (let key in values.value) {
                if (key == "reason") {    
                    if (values.value[key].length == 0) {
                        error.value = "נא לבחור סיבת הבעיה";
                        return false;
                    }
                }
                if (key == "description") {    
                    if (values.value[key].length == 0) {
                        error.value = "נא לכתוב את תיאור הבעיה";
                        return false;
                    }
                }
                if (key == "uploadedDocuments") {    
                    if (values.value[key].length == 0) {
                        error.value = "נא להעלות לפחות צילום אחד";
                        return false;
                    }
                }
                
            }
            return true
        }

        const handleSubmit=async()=>{
            if(!validation()){
                swal("בעיה", `${error.value}`, "warning");
            }else{
                   const doc = projectFirestore
                    .collection("Applications")
                    .doc("iW7LKA8GsRduHDOSFxq1")
                    .collection("Reports")
                    .doc();
                    await doc.set({ ...values.value, id: doc.id });
                    clearData()
                    swal("הצלחה", "הטופס נשלח בהצלחה", "success");
            }
        }
        const clearData=()=>{
            const temp={
                reason:'',
                description:'',
                uploadedDocuments:[],
                branche:values.value.branche,
                status:'חדש',
                tihud:'',
                createdAt:new Date()
            }
            values.value=temp
        }


        onMounted(()=>{
                setTimeout(() => {
                    if (store.getters.user.branche) {
                        values.value.branche = store.getters.user.branche;
                    } else {
                     values.value.branche = store.getters.user.display_name;
                    }
                }, 1000);
        })
        return{reasonsOptions,handleUpload,handleChange,values,
        isPending,error,file,fileError,handleOpenPdf,delete_file,handleSubmit}
    }
}
</script>

<style scoped>
    .container{
        width: 80vw;
        height: 70vh;
        overflow:hidden;
    }
    .wrapper{
        width: 100%;
        height: calc(70vh - 7rem);
        overflow: hidden;
        overflow-y: auto;
    }
    .field{
        width: 100%;
        height: auto;
        margin-top: 10px;
        
    }
    .images{
        margin-top: 2px;
        width: 100%;
        height: 20vh;
    }
    .images {
  width: 100%;
  height: 30vh;
  padding: 3px;
  /* background: chartreuse; */
  display: flex;
  background-color: white;
  border-radius: 5px;
  margin-top: 5px;
  overflow: hidden;
}
.images .image {
  position: relative;
  width: 33.3%;
  height: 100%;
  grid-gap: 5px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.images .image .delete-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15%;
  background-color: rgb(145, 3, 3);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 5px;
}
.images .image img {
  max-width: 100%;
  max-height: 100%;
}
.image-prev {
  width: 100%;
  height: 20vh;
  display: flex;
  background: chartreuse;
}
</style>