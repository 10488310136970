<template>
  <div class="orders-of-today">
      <div class="orders-of-today-wrraper slide-in-top">
          <div @click="handle_close" class="exit-btn">X</div>
        
          <div class="orders-of-today-wrraper-header">
              <h4> פריטים בהזמנות להיום {{new Date().toLocaleDateString('he')}}</h4>
          </div>
          <div class="orders-of-today-wrraper-tools">
              <el-input style="width:30%" v-model="search" placeholder="חיפוש" />
              <i class="material-icons print-icon" @click="print_table">print</i>
             <div class="logo">
              <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Frosman-market_375x375.png?alt=media&token=b055241e-d401-4ae2-9c14-178553610dc2" alt="">
            </div>
          </div>
          <div v-if="records.length==0" v-loading="is_pending" class="orders-of-today-wrraper-content">
              <h1>לא קיימות הזמנות</h1>
          </div>
          <div v-if="records.length>0" class="orders-of-today-wrraper-content">
               <table id="table">
                  <tr>
                      <th>קוד</th>
                      <th>פריט</th>
                      <th>כמות</th>
                      <th>מחיר נוכחי</th>
                      <th class=".no-print">פעולות</th>
                  </tr>
                  <h1 v-if="records.length==0">לא קיימות הזמנות</h1>
                  <template v-for="product in sort_records" :key="product.barcode">
                      <tr>  
                          <td>{{product.barkod}}</td>
                          <td>{{product.ItemName}}</td>
                          <td>{{product.currentOrder}}</td>
                          <td style="color:green;">{{product.cost}} &#8362;</td>
                          <td class=".no-print" @click="handle_show_costs_history(product)"><i class="material-icons info-icon">info</i></td>
                      </tr>
                  </template>
              </table>
          </div>
      </div>

      <CostsHistory @close="show_costs_history=false" v-if="show_costs_history" :product="selected_product" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../../../../../../firebase/config'
import { computed, watch } from '@vue/runtime-core'
import CostsHistory from './CostsHistory.vue'
export default {
    components:{CostsHistory},
    emits:['close'],
    setup(_,{emit}){
        const search = ref('')
        const is_pending = ref(true)
        const records = ref([])
        const branches = ref([])
        const selected_product = ref(null)
        const show_costs_history = ref(false)
        const handle_close = ()=>{
            emit('close')
        }

        const handle_show_costs_history = (product)=>{
            console.log(product);
            selected_product.value = product
            show_costs_history.value = true
        }
        const filter_by_search = ref(computed(()=>{
            if(search.value=='') return records.value
            return records.value.filter((product)=>{
                if(product.ItemName.includes(search.value)) return product
                if(product.barkod.includes(search.value)) return product
            })
        }))

        const sort_records = ref(computed(()=>{
            return filter_by_search.value.sort((a,b)=>(a.ItemName.trim() > b.ItemName.trim()) ? 1 : ((b.ItemName.trim() > a.ItemName.trim()) ? -1 : 0))
        }))
       

        const print_table = ()=>{
            var styleElem = document.createElement('style');
            styleElem.type = 'text/css' ;
            var css = `
                 @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
                *{
                    direction:rtl;
                    font-family: 'Rubik', sans-serif;
                }
                 table {
                    border-collapse: collapse;
                    width: 100%;
                }
                table td, table th {
                    border: 1px solid #ddd;
                    padding: 5px;
                
                }
                table tr:nth-child(even){background-color: #f2f2f2;}
                table tr:nth-child(odd){background-color: #e7e6e6;}
                table tr:hover {background-color: #ddd;}
                table td{
                    text-align: center;
                    color: var(--main);
                    font-size: 18px;

                }
                table th {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    text-align: center;
                    background-color: #333;
                    color: white;
                    position: sticky;
                    top: 0;
                    font-size: 18px;
                    font-weight: 500;
                }
                @page {
                    size: a4 landscape;
                    margin: 0;
                    padding: 0;
                    margin-top: 20mm;
                }
                @media print {
                    i{
                        display: none !important;
                    }
                }
            `
            if(styleElem.styleSheet){
                styleElem.styleSheet.cssText = css;
            }
            else{
                styleElem.appendChild(document.createTextNode(css));
            }
            var divToPrint=document.getElementById("table");
            let newWin= window.open('', '', 'height=500, width=500');
            newWin.document.write(divToPrint.outerHTML);
            newWin.document.getElementsByTagName('head')[0].appendChild(styleElem);
            newWin.document.close();
            newWin.print();

        }

        const organize_data = async()=>{
            const docs =  await projectFirestore.collection('Applications').doc('iW7LKA8GsRduHDOSFxq1').collection('Orders')
            .where('status','==','open').get()
            let temp_orders = docs.docs.map(doc=>doc.data())
            temp_orders = temp_orders.filter(order=>new Date().toLocaleDateString('he') == new Date(order.createdAt.seconds*1000).toLocaleDateString('he'))
            temp_orders.map(order=>order.products).forEach(products=>{
                products.forEach(async product=>{
                    const index = records.value.findIndex(_product=>_product.barkod==product.barkod)
                    if(index == -1){
                        records.value.push(product)
                    }else{
                        records.value[index].currentOrder= Number(records.value[index].currentOrder)+Number(product.currentOrder)
                    }
                })
            })
        }
        const init = async()=>{
            await organize_data()
            is_pending.value = false
        }

        init()

       
        return{
            handle_close,
            print_table,
            handle_show_costs_history,
            records,
            branches,
            is_pending,
            search,
            filter_by_search,
            show_costs_history,
            selected_product,
            sort_records
        }
    }
}
</script>

<style scoped>
    .orders-of-today{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        padding: 0 5px;
        color: #333;
    }
    .orders-of-today-wrraper{
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        height: 100%;
        background: #fff;
        border-radius: 5px;
    }
    .exit-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        background: crimson;
        border-radius: 50%;
        z-index: 21;
        cursor: pointer;
        color: #fff;
    }
    .logo{
        position: absolute;
        top: 0;
        left: 5px;
        width: 50px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 19;
        cursor: pointer;
    }
    .logo img{
        max-width: 100%;
        max-height: 100%;
    }
    .orders-of-today-wrraper-header{
     
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .orders-of-today-wrraper-tools{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .print-icon{
        margin-right: 5px;
        font-size: 30px;
        color: cornflowerblue;
        cursor: pointer;
    }
    .orders-of-today-wrraper-content{
        width: 100%;
        height: calc(100% - 100px);
        padding-bottom: 5px;
        overflow-x: auto;
        overflow-y: auto;
    }

.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
        font-size: 10px;
        font-weight: 500;
    }
    .info-icon{
        cursor: pointer;
    }
    @media print
    {    
        .no-print, .no-print *
        {
            display: none !important;
        }
    }

</style>